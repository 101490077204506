import { Flex, Text, theme } from '@traefiklabs/faency'
import React from 'react'
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton'
import Icon from 'react-eva-icons'
import styled from 'styled-components'

const StyledWrapper = styled(Wrapper)`
  display: inline-block;
  position: relative;

  &:focus {
    outline: 0;
  }
`

const StyledMenu = styled(Menu)`
  overflow: hidden;
  background: #fff;
  list-style-type: none;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  padding: 4px 0;
  border-radius: ${theme.radii[1]};
  margin: 0;
  box-shadow: rgba(14, 18, 22, 0.35) 0px 10px 38px -10px, rgba(14, 18, 22, 0.2) 0px 10px 20px -15px;
  width: 180px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  padding: 10px 16px;
  ${(props) =>
    props.variant === 'negative'
      ? `
      color: ${props.variant === 'negative' ? 'red' : 'inherit'};
    `
      : ''}

  &:hover,
  &:focus {
    outline: 0;
    font-weight: bold;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${theme.colors.grays[5]};

  ${(props) =>
    props.disabled
      ? ``
      : `&:hover,
  &:focus {
    cursor: pointer;
    background-color: ${theme.colors.grays[1]};
    color: ${theme.colors.grays[5]};
    border-radius: ${theme.radii[2]};
  }`};

  &:focus {
    outline: 0;
  }
`

type PopoverMenuItem = {
  id: string
  title: string
  variant?: string
  icon: string
  onPress: () => void
}

type PopoverMenuProps = {
  menuItems?: PopoverMenuItem[]
  disabled?: boolean
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({ menuItems = [], disabled = false, ...props }) => {
  const handleSelection = (selectedElem) => {
    const menuItem = menuItems.find((mi) => mi.id === selectedElem.key)
    menuItem.onPress()
  }

  return (
    <StyledWrapper
      onSelection={handleSelection}
      {...(!disabled
        ? {
            onClick: (e) => {
              e.stopPropagation()
              e.preventDefault()
            },
          }
        : {})}
      {...props}
    >
      <StyledButton disabled={disabled}>
        <Icon name="more-vertical-outline" fill="currentColor" size="large" />
      </StyledButton>
      <StyledMenu>
        {menuItems.map((item) => (
          <StyledMenuItem key={item.id} id={item.id} variant={item?.variant} disabled={disabled}>
            <Flex key={item.id}>
              <Icon name={item.icon} fill="currentColor" />
              <Text pl={1} sx={{ color: 'inherit' }}>
                {item.title}
              </Text>
            </Flex>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </StyledWrapper>
  )
}

export default PopoverMenu
